.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container input {
    height: 64px;
    border: none;
    border-bottom: 2px solid transparent;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
}

.input-container input:focus {
    box-shadow: none;
}

.input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #000;
    font-size: 12px;
    line-height: 1;
    left: 16px;
    font-family: "Noto Sans";
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.92px;
    line-height: 140%;
}

.input-container .filled {
    transform: translate(0, 12px) scale(0.8);
}

.input-container label.filled {
    transform: translate(0, 12px) scale(0.8);
    color: #000;
}

.input-container .input-border {
    height: 2px;
    width: 100%;
    transition: background-color 200ms ease-in-out;
    background-color: black;
}

.input-container .input-error + .input-border {
    background-color: red;
}

.error-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    align-self: flex-start;
}
